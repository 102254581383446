import React from 'react';
import {Step, StepLabel, Stepper} from "@mui/material";
import TeamData from "./teamData";
import Sectors from "./sectors";
import Members from "./members";
import Products from "./products";
import Button from "../../Components/Button";
import request from "../../Services/Request";

function OnBoard(props) {
    const [currentStep, setCurrentStep] = React.useState(0);
    const [teamData, setTeamData] = React.useState(false);
    const [ownerData, setOwnerData] = React.useState(false);
    const [sectorsAdded, setSectorsAdded] = React.useState([]);
    const [membersAdded, setMembersAdded] = React.useState([]);
    const [productsAdded, setProductsAdded] = React.useState([]);
    const [finished, setFinished] = React.useState(false);

    const submit = () => {
        request("teams/onboard/", "POST", {
            team: teamData,
            owner: ownerData,
            sectors: sectorsAdded,
            members: membersAdded,
            products: productsAdded
        }, true)
        setFinished(true);
    }

    const steps = [
        {
            title: "Dados da Equipe",
            element: <TeamData
                active={currentStep === 0}
                teamData={teamData}
                setTeamData={setTeamData}
                ownerData={ownerData}
                setOwnerData={setOwnerData}
            />
        },
        {
            title: "Agências",
            element: <Sectors
                active={currentStep === 1}
                sectorsAdded={sectorsAdded}
                setSectorsAdded={setSectorsAdded}
            />
        },
        {
            title: "Corretores",
            element: <Members
                active={currentStep === 2}
                sectorsAdded={sectorsAdded}
                membersAdded={membersAdded}
                setMembersAdded={setMembersAdded}
            />
        },
        {
            title: "Produtos",
            element: <Products
                active={currentStep === 3}
                productsAdded={productsAdded}
                setProductsAdded={setProductsAdded}
            />
        },
        {
            title: "Confirmação",
            element: currentStep === 4 && <>
                <h3>Confirme as informações adicionadas</h3>
                <div style={{display: "flex", alignItems: "center", columnGap: 5}}>
                    <h4>Nome da equipe:</h4> {teamData.name}</div>
                <div style={{display: "flex", alignItems: "center", columnGap: 5}}>
                    <h4>Agências:</h4>
                    <div>
                        {sectorsAdded.map(sec => {
                            return <div>{sec.name}</div>
                        })}
                    </div>
                </div>
                <div style={{display: "flex", alignItems: "center", columnGap: 5}}>
                    <h4>Corretores:</h4>
                    <div>
                        {membersAdded.map(member => {
                            return <div>{member.name} - {member.email}</div>
                        })}
                    </div>
                </div>
                <div style={{display: "flex", alignItems: "center", columnGap: 5}}>
                    <h4>Produtos:</h4>
                    <div>
                        {productsAdded.map(sec => {
                            return <div>{sec.name} -> {sec.type}</div>
                        })}
                    </div>
                </div>
            </>
        }
    ]

    console.log("DATA ADDED", teamData, sectorsAdded, membersAdded, productsAdded, ownerData);

    return (
        <div style={{
            width: '100dvw',
            height: '100dvh',
            padding: 20,
            boxSizing: 'border-box',
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: "#3F0B0E",
        }}>
            <div style={{
                width: '80%',
                height: "90%",
                display: 'flex',
                flexDirection: 'column',
                justifyContent: "flex-start",
                rowGap: 15
            }}>
                <div style={{
                    height: '95%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: "space-between"
                }}>
                    <div style={{
                        display: "flex",
                        // justifyContent: "space-between",
                        height: "10%",
                        width: "100%",
                        backgroundColor: "#f3f3f3",
                        borderRadius: 10
                    }}>
                        <Stepper style={{
                            width: "100%",
                        }}>
                            {steps.map((step, index) => (
                                <Step key={index} completed={currentStep > index} active={currentStep === index}>
                                    <StepLabel>{step.title}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </div>
                    <div style={{
                        height: "85%",
                        backgroundColor: "#f3f3f3",
                        padding: 20,
                        boxSizing: "border-box",
                        borderRadius: 10,
                        display: "flex",
                        flexDirection: 'column',
                        justifyContent: "space-between"
                    }}>
                        {finished ? <div>
                            <h1>Informações salvas!</h1>
                            <h3>Entraremos em contato em breve com seu acesso.</h3>
                        </div> : <div style={{
                            height: "95%",
                        }}>
                            {steps.map((step, index) => (
                                step.element
                            ))}
                            {/*</div>*/}
                            <div style={{display: 'flex', justifyContent: "space-between", height: "5%"}}>
                                <Button onClick={() => currentStep > 0 && setCurrentStep(currentStep - 1)}>
                                    Voltar
                                </Button>
                                <Button
                                    onClick={() => currentStep === 4 ? submit() : currentStep < 4 && setCurrentStep(currentStep + 1)}>
                                    {currentStep === 4 ? "Finalizar" : "Próximo"}
                                </Button>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OnBoard;