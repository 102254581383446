import React, { useState } from 'react';
import { PiBankDuotone } from "react-icons/pi";

function Sectors(props) {
    const { sectorsAdded, setSectorsAdded } = props;

    const [sectorName, setSectorName] = useState('');
    const [sectorCode, setSectorCode] = useState('');
    const [sectorPrime, setSectorPrime] = useState(true);

    const handleAddSector = (e) => {
        e.preventDefault();
        if (sectorPrime) {
            const newSector = {
                name: sectorName,
                code: `${sectorCode}-PRIME`,
                prime: true,
            };

            setSectorsAdded((prev) => [...prev, newSector]);
            const newSector2 = {
                name: sectorName,
                code: sectorCode,
                prime: false,
            };

            setSectorsAdded((prev) => [...prev, newSector2]);
        } else {
            const newSector = {
                name: sectorName,
                code: sectorCode,
                prime: sectorPrime,
            };

            setSectorsAdded([...sectorsAdded, newSector]);
        }
        setSectorName('');
        setSectorCode('');
        setSectorPrime(false);
    };

    if (!props.active) return null;

    return (
        <div style={{ height: "100%" }}>
            <h3>Agora, vamos cadastrar as agências da sua equipe.</h3>
            <div style={{
                display: 'flex',
                width: '100%',
                height: '100%',
                justifyContent: "flex-start",
                columnGap: 50
            }}>
                <div>
                    <form
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            width: 350
                        }}
                        onSubmit={(e) => handleAddSector(e)}
                    >
                        <div className={"FormField"}>
                            <label htmlFor="sectorName">Nome</label>
                            <input
                                id="sectorName"
                                type="text"
                                value={sectorName}
                                onChange={(e) => setSectorName(e.target.value)}
                            />
                        </div>
                        <div className={"FormField"}>
                            <label htmlFor="sectorCode">Código</label>
                            <input
                                id="sectorCode"
                                type="text"
                                value={sectorCode}
                                onChange={(e) => setSectorCode(e.target.value)}
                            />
                        </div>
                        <div className={"FormField"}>
                            <label htmlFor="sectorPrime">Possui espaço Prime?</label>
                            <input
                                id="sectorPrime"
                                type="checkbox"
                                checked={sectorPrime}
                                onChange={(e) => setSectorPrime(e.target.checked)}
                            />
                        </div>
                        <input type="submit" value="Adicionar"/>
                    </form>
                </div>
                <div style={{
                    backgroundColor: "#cfc7c7",
                    width: "100%",
                    height: "80%",
                    padding: 20,
                    boxSizing: "border-box",
                    borderRadius: 30,
                    display: "flex",
                    alignContent: "flex-start",
                    flexWrap: "wrap",
                    whiteSpace: "nowrap",
                    gap: 20,
                    overflow: "scroll",
                }}>
                    {sectorsAdded.map((sector, index) => (
                        <div
                            key={index}
                            style={{
                                display: "flex",
                                padding: 10,
                                maxHeight: 35,
                                width: 210,
                                marginBottom: 10,
                                boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1)",
                                backgroundColor: "white",
                                borderRadius: 10,
                                columnGap: 15
                            }}
                        >
                            <PiBankDuotone/>
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                maxWidth: '50%',
                                overflow: 'hidden',
                                whiteSpace: "nowrap",
                            }}>
                                <span style={{overflow: "hidden", textOverflow: "ellipsis",whiteSpace: "nowrap",}}>{sector.name}</span>
                                <span style={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                }}>{sector.code}</span>
                            </div>
                            {sector.prime && <div style={{
                                display: "flex",
                                fontSize: 10,
                                color: "green",
                                border: "1px solid green",
                                borderRadius: 5,
                                height: "fit-content",
                                padding: 3
                            }}>PRIME</div>}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Sectors;
