import React from 'react';
import "./styles.css";
import InputMask from "react-input-mask";

function TeamData(props) {
    const {active, teamData, setTeamData, ownerData, setOwnerData} = props

    const handleSubmit = (e) => {
        e.preventDefault();
    }

    const handleChange = (e) => {
        setTeamData({
            ...teamData,
            name: e.target.value
        })
    }

    const handleChangeOwner = (field, value) => {
        setOwnerData({
            ...ownerData,
            [field]: value
        })
    }

    if (!active) return null

    return (
        <div style={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "flex-start", flexDirection: "column" }}>
            <h3 style={{marginBottom: 30}}>Vamos iniciar o cadastro da sua equipe no Masterprod</h3>
            <form onSubmit={handleSubmit}>
                <div className={"FormField"}>
                    <label>Nome da Equipe</label>
                    <input
                        value={teamData.name}
                        onChange={handleChange}
                    />
                </div>
                <div className={"FormField"}>
                    <label>Seu nome</label>
                    <input
                        value={ownerData.name}
                        onChange={(e) => handleChangeOwner('name', e.target.value)}
                    />
                </div>
                <div className={"FormField"}>
                    <label>Seu email</label>
                    <input
                        value={ownerData.email}
                        onChange={(e) => handleChangeOwner('email', e.target.value)}
                    />
                </div>
                <div className={"FormField"}>
                    <label>Seu Telefone</label>
                    <InputMask
                        name="phone"
                        mask={"(99) 9 9999-9999"}
                        value={ownerData.phone}
                        onChange={(e) => handleChangeOwner('phone', e.target.value)}
                    />
                </div>
            </form>
        </div>
    )
}

export default TeamData;