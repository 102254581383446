import React from 'react';
import {FaRegUser} from "react-icons/fa6";
import {Switch} from "@mui/material";

function Members(props) {
    const {sectorsAdded, membersAdded, setMembersAdded} = props;
    const [sectorsSelected, setSectorsSelected] = React.useState([]);

    const memberNameRef = React.useRef(null);
    const memberEmailRef = React.useRef(null);

    const handleAddMember = (e) => {
        e.preventDefault();
        const newMember = {
            name: memberNameRef.current.value,
            email: memberEmailRef.current.value,
            sectors: sectorsSelected
        }

        setMembersAdded([...membersAdded, newMember])
    }

    if (!props.active) return null

    return (
        <div style={{height: '90%'}}>
            <h3 style={{margin: 4}}>Agora, cadastre seus corretores.</h3>

            <div style={{height: '100%', display: 'flex', width: '100%', justifyContent: 'space-between'}}>
                <div style={{
                    display: 'flex',
                    width: '30%',
                    justifyContent: "space-between",
                    columnGap: 50,
                    height: '100%',
                }}>
                    <form
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            width: 280,
                            height: "100%",
                            justifyContent: 'space-between',
                        }}
                        onSubmit={(e) => handleAddMember(e)}
                    >
                        <div style={{height: '40%'}}>
                            <div className={"FormField"} style={{marginBottom: 10}}>
                                <label htmlFor="sectorName">Nome</label>
                                <input ref={memberNameRef} id={"sectorName"} type={"text"}/>
                            </div>
                            <div className={"FormField"}>
                                <label htmlFor="sectorCode">Email</label>
                                <input ref={memberEmailRef} id={"sectorCode"} type={"email"}/>
                            </div>
                        </div>
                        <div style={{height: '60%'}}>
                            <h4 style={{height: '5%', marginTop: 0}}>Agências atendidas</h4>
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                height: '75%',
                                overflow: 'scroll',
                            }}>
                                {
                                    sectorsAdded.map((item, index) => (
                                        <>
                                            <div style={{
                                                display: 'flex',
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                height: 30
                                            }}>
                                                <label htmlFor={'sector' + index} key={index}>a {item.name} {item.prime ? ` - prime` : ``}</label>
                                                <Switch
                                                    onChange={(e) => {
                                                        const checked = e.target.checked;
                                                        const sector = item.code
                                                        if (checked) {
                                                            setSectorsSelected([...sectorsSelected, item.code])
                                                        } else {
                                                            setSectorsSelected([...sectorsSelected.filter(x => x !== sector)])
                                                        }
                                                    }}
                                                    checked={sectorsSelected.includes(item.code)}
                                                />
                                            </div>
                                        </>
                                    ))
                                }
                            </div>
                            <input style={{
                                backgroundColor: '#233771',
                                color: 'white',
                                border: 'none',
                                height: 30,
                                width: 300,
                                borderRadius: 5
                            }} type={"Submit"} value={"Adicionar"}/>
                        </div>
                    </form>
                </div>
                <div style={{
                    backgroundColor: "#cfc7c7",
                    width: "65%",
                    height: "100%",
                    padding: 20,
                    boxSizing: "border-box",
                    borderRadius: 30,
                    overflow: 'scroll',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignContent: "flex-start",
                    gap: 10,
                    flexWrap: 'wrap',
                }}>
                    {/*{*/}
                    {/*    sectorsSelected.map((item, index) => (*/}
                    {/*        <div>*/}
                    {/*            {sectorsAdded.find(x => x.id === item)?.name}*/}
                    {/*        </div>*/}
                    {/*    ))*/}
                    {/*}*/}
                    {
                        membersAdded.map((member, index) => (
                            <div
                                key={index}
                                style={{
                                    display: "flex",
                                    padding: 15,
                                    width: 230,
                                    height: 30,
                                    boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1)",
                                    backgroundColor: "white",
                                    borderRadius: 10
                                }}
                            >
                                <FaRegUser/>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column"
                                }}>
                                    <span>{member.name}</span>
                                    <span style={{
                                        fontSize: 10,
                                    }}>{member.email}</span>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    );
}

export default Members;