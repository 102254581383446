import React from 'react';
import {PiBankDuotone} from "react-icons/pi";
import "./styles.css";

function Products(props) {

    const {active, productsAdded, setProductsAdded} = props

    const productNameRef = React.useRef(null);
    const productTypeRef = React.useRef(null);

    const handleAddProduct = (e) => {
        e.preventDefault();
        const newProduct = {
            name: productNameRef.current.value,
            type: productTypeRef.current.value,
        }

        setProductsAdded([...productsAdded, newProduct])
    }

    if (!active) return null

    return (
        <>
            <h3>Informe os produtos com os quais a sua equipe trabalha.</h3>
            <div style={{
                display: 'flex',
                width: '100%',
                height: '100%',
                justifyContent: "flex-start",
                columnGap: 50
            }}>
                <div>
                    <form
                        onSubmit={(e) => handleAddProduct(e)}
                    >
                        <div className={"FormField"}>
                            <label htmlFor="productName">Nome</label>
                            <input ref={productNameRef} id={"productName"} type={"text"}/>
                        </div>
                        <label htmlFor="productCode">Por valor ou quantidade?</label>
                        <select ref={productTypeRef}>
                            <option value={"V"}>Valor</option>
                            <option value={"Q"}>Quantidade</option>
                        </select>
                        <input type={"Submit"} value={"Adicionar"}/>
                    </form>
                </div>
                <div style={{
                    backgroundColor: "#cfc7c7",
                    width: "100%",
                    height: "80%",
                    padding: 30,
                    boxSizing: "border-box",
                    borderRadius: 30,
                    display: "flex",
                    gap: 10,
                    flexWrap: "wrap",
                    alignContent: "flex-start",
                    overflow: "scroll"
                }}>
                    {
                        productsAdded.map((product, index) => (
                            <div
                                key={index}
                                style={{
                                    display: "flex",
                                    padding: 15,
                                    width: 135,
                                    boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1)",
                                    backgroundColor: "white",
                                    borderRadius: 10,
                                    columnGap: 15,
                                    height: 30,
                                }}
                            >
                                <PiBankDuotone/>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column"
                                }}>
                                    <span>{product.name}</span>
                                    <span style={{fontSize: 10}}>{product.type === "V" ? "Valor" : "Quantidade"}</span>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </>
    );
}

export default Products;