import React from 'react';
import './SidebarFooter.scss';
import {FaArrowRightFromBracket} from "react-icons/fa6";
import {useDispatch} from "react-redux";
import {logout} from "../../Services/Auth/authSlice";

export const SidebarFooter = ({ collapsed }) => {
    const dispatch = useDispatch()
    return (
        <div className={"SidebarFooterContainer"}>
            <div className={"SidebarFooterItem"} onClick={() => dispatch(logout())}>
                <FaArrowRightFromBracket />
                {!collapsed ? <span>Sair</span> : null}
            </div>
            {/*<Link to={"/profile"} className={"SidebarFooterItemSmall"} onClick={() => redirect('profile/')}>*/}
            {/*    <FaGears color={"white"}/>*/}
            {/*</Link>*/}
            {/*<div className={"SidebarFooterItemSmall"}>*/}
            {/*    <Link to={"settings/"} children={*/}
            {/*        <FaGears color={"white"}/>*/}
            {/*    }/>*/}
            {/*</div>*/}
        </div>
    );
};
