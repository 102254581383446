import {isLoggedIn, logout} from "./Auth";
import {resetAuth} from "./Auth/authSlice";
import {resetApp} from "../AppSlice";
import {resetGoals} from "../Pages/Goals/goalsSlice";
import {resetMembers} from "../Pages/Members/MembersSlice";
import {resetProducts} from "../Pages/Products/ProductsSlice";
import {resetSectors} from "../Pages/Sectors/SectorsSlice";

const host = process.env.REACT_APP_HOST_URL || "https://api.masterprod.com.br/"
// const host = "http://localhost:8000/"


export default function request(endpoint, method, data, ignoreToken) {
    let requestInit = {
        method: method,
        headers: new Headers({
            'content-type':'application/json'
        }),
        mode: 'cors',
        cache: 'default'
    };

    if (!ignoreToken){
        const token = localStorage.getItem("auth-token")
        requestInit.headers.append('Authorization', 'Bearer ' + token);
    }

    if (method !== 'GET') requestInit['body'] = JSON.stringify(data)

    return fetch(`${host}${endpoint}`, requestInit).then((response) => {
        if (response.status === 401 && isLoggedIn()) {
            logout()
            window.location.reload();
        }
        return response.json().then(res => {
            return res
        })
    })
}
